.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slider-wrapper {
  width: 760px;
  margin: auto;
}
.slick-slide {
  text-align: center;
  position: relative;
}
.slick-slide:focus {
  outline: none;
}
.slick-slide-title {
  text-transform: capitalize;
}
.slick-slide-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}
.slick-slide-label {
  color: #fff;
  padding: 10px;
  position: absolute;
  left: 0px;
  font-size: 1.5em;
  bottom: 0px;
  width: 100%;
}
.slick-prev:before,
.slick-next:before {
  color: #777777;
}
.slick-list {
  width: 100%;
}
.slick-next {
  right: 0.1rem;
}
.slick-prev {
  left: 0.1rem;
}
.setting-box {
  display: flex;
  gap: 2rem;
}
.setting-box_component {
  font-weight: 600;
  color: green;
  font-size: 20px;
  height: 10rem;
  width: 15rem;
  min-width: 12rem;
  border-radius: 1rem;
  background: #f8f8f8;
  overflow: hidden;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; /* Center the text vertically */
}
